<template>
  <div class="login">
    <systemHeader></systemHeader>
    <section class="content_">
      <section class="left_">
        <img src="../../assets/img/login_banner.png" alt="" />
      </section>
      <section class="right_">
        <el-form
          label-position="top"
          label-width="80px"
          ref="validForm"
          :model="form_data"
          :rules="rules"
          @submit.native.prevent
          @keyup.enter.native="loginHandle()"
        >
          <el-form-item label="账号" prop="name">
            <el-input
              v-model="form_data.name"
              autocomplete="off"
              type="text"
              placeholder="请输入账号"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="pass">
            <el-input
              v-model="form_data.pass"
              type="password"
              placeholder="请输入密码"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              size="medium"
              @click="loginHandle()"
              style="width: 100%; margin-top: 10px"
              >登 录</el-button
            >
            <!-- <el-input v-model="formLabelAlign.type"></el-input> -->
          </el-form-item>
        </el-form>
      </section>
    </section>
    <commonFooter></commonFooter>
  </div>
</template>
<script>
import systemHeader from "@/components/systemHeader";
import commonFooter from "@/components/commonFooter";
export default {
  components: {
    systemHeader,
    commonFooter,
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入账号"));
      } else {
        callback();
        // if (value != "admin") {
        //   callback(new Error("账号错误"));
        // } else {
        // }
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
        // if (value !== "123456") {
        //   callback(new Error("密码错误"));
        // } else {
        // }
      }
    };
    return {
      form_data: {
        name: "",
        pass: "",
      },
      rules: {
        name: [{ validator: validatePass, trigger: "blur" }],
        pass: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  methods: {
    saveLoginUserToCookie(data) {
      this.$cookies.set("hotpots_token_from_rocket", data.token);
    },
    loginHandle() {
      this.$refs["validForm"].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          });
          this.axios("post", `${this.$baseUrl}v1/login`, {
            name: this.form_data.name,
            pass: this.form_data.pass,
          })
            .then((res) => {
              loading.close();
              if (res.code == 1) {
                this.saveLoginUserToCookie(res.data);
                localStorage.setItem("token", res.data.token);
                localStorage.setItem(
                  "is_mcn",
                  res.data.user_info.is_mcn ? 1 : 2
                );
                let pageType = localStorage.getItem("pageType");

                if (pageType) {
                  if (pageType === "3") {
                    if (res.data.user_info.is_mcn) {
                      localStorage.setItem("activePath", "summaryReport");
                      this.$router.push("/summaryReport");
                    } else {
                      localStorage.setItem("pageType", "1");
                      localStorage.setItem("activePath", "tiktokSearch");
                      window.location.href = "/tiktokSearch";
                    }
                  } else if (pageType === "1") {
                    localStorage.setItem("activePath", "tiktokSearch");
                    this.$router.push("/tiktokSearch");
                  } else {
                    localStorage.setItem("activePath", "classifySetting");
                    this.$router.push("/classifySetting");
                  }
                } else {
                  localStorage.setItem("pageType", "1");
                  localStorage.setItem("activePath", "tiktokSearch");
                  window.location.href = "/tiktokSearch";
                }
              }
            })
            .catch((err) => {
              loading.close();
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  .content_ {
    height: calc(100vh - 116px);
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    .left_ {
      flex: 1;
      overflow: hidden;
      height: 100%;
      object-fit: cover;
      display: grid;
      align-items: center;
      justify-content: center;
      // background: url(../../assets/img/login_banner.png) no-repeat center center;
      img {
        min-height: 100%;
      }
    }
    .right_ {
      background: linear-gradient(180deg, #fbfbfb 0%, #f6fbff 100%);
      flex: 0 0 600px;
      display: flex;
      align-items: center;
      justify-content: center;
      :deep(.el-form) {
        width: 500px;
        margin-bottom: 100px;
        .el-form-item {
          .el-form-item__label {
            line-height: 28px;
          }
          .el-button--medium {
            padding: 15px;
          }
        }
      }
    }
  }
}
</style>
